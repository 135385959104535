export const LOGIN_API_PATH = "auth--dummy-suffix-url/login";
export const AUTH_USER_API_PATH = "auth--dummy-suffix-url/auth-user";

export const MANIFESTS_API_PATH = "manifest";
export const GET_ALL_MANIFESTS_API_PATH = `${MANIFESTS_API_PATH}/all`;
export const GET_ALL_MANIFESTS_UNPAGINATED_API_PATH = `${MANIFESTS_API_PATH}/all-unpaginated`;
export const GET_MANIFESTS_STATS_API_PATH = `${MANIFESTS_API_PATH}/stats?manifest_ids={manifestIds}`;
export const GET_SINGLE_MANIFEST_API_PATH = `${MANIFESTS_API_PATH}?id={manifestId}`;
export const GET_MANIFEST_LAST_LOT_API_PATH = `${MANIFESTS_API_PATH}/check-last-lot?id={manifestId}`;
export const SET_IS_HIDDEN_MANIFEST_API_PATH = `${MANIFESTS_API_PATH}/set-is-hidden?id={manifestId}&is_hidden={isHidden}`;
export const ADD_MANIFEST_API_PATH = `${MANIFESTS_API_PATH}/add`;
export const EDIT_MANIFEST_API_PATH = `${MANIFESTS_API_PATH}/edit?id={manifestId}`;
export const DELETE_MANIFEST_API_PATH = `${MANIFESTS_API_PATH}?id={manifestId}`;

export const MANIFEST_GROUPS_API_PATH = "manifest-group";
export const GET_ALL_MANIFEST_GROUPS_API_PATH = `${MANIFEST_GROUPS_API_PATH}/all`;
export const GET_ALL_MANIFEST_GROUPS_UNPAGINATED_API_PATH = `${MANIFEST_GROUPS_API_PATH}/all-unpaginated`;
export const GET_MANIFEST_GROUPS_STATS_API_PATH = `${MANIFEST_GROUPS_API_PATH}/stats?manifest_group_ids={manifestGroupIds}`;
export const GET_SINGLE_MANIFEST_GROUP_API_PATH = `${MANIFEST_GROUPS_API_PATH}?id={manifestGroupId}`;
export const SET_IS_HIDDEN_MANIFEST_GROUP_API_PATH = `${MANIFEST_GROUPS_API_PATH}/set-is-hidden?id={manifestGroupId}&is_hidden={isHidden}`;
export const ADD_MANIFEST_GROUP_API_PATH = `${MANIFEST_GROUPS_API_PATH}/add`;
export const EDIT_MANIFEST_GROUP_API_PATH = `${MANIFEST_GROUPS_API_PATH}/edit?id={manifestGroupId}`;
export const DELETE_MANIFEST_GROUP_API_PATH = `${MANIFEST_GROUPS_API_PATH}?id={manifestGroupId}`;

export const VENDORS_API_PATH = "vendor";
export const GET_ALL_VENDORS_API_PATH = `${VENDORS_API_PATH}/all`;
export const GET_SINGLE_VENDOR_API_PATH = `${VENDORS_API_PATH}?id={vendorId}`;
export const ADD_VENDOR_API_PATH = `${VENDORS_API_PATH}/add`;
export const EDIT_VENDOR_API_PATH = `${VENDORS_API_PATH}/edit?id={vendorId}`;
export const DELETE_VENDOR_API_PATH = `${VENDORS_API_PATH}?id={vendorId}`;
export const SET_IS_HIDDEN_VENDOR_API_PATH = `${VENDORS_API_PATH}/set-is-hidden?id={vendorId}&is_hidden={isHidden}`;

export const ZPL_TEMPLATES_API_PATH = "zpl-template";
export const GET_ALL_ZPL_TEMPLATES_API_PATH = `${ZPL_TEMPLATES_API_PATH}/all`;
export const GET_SINGLE_ZPL_TEMPLATE_API_PATH = `${ZPL_TEMPLATES_API_PATH}?id={zplTemplateId}`;
export const ADD_ZPL_TEMPLATE_API_PATH = `${ZPL_TEMPLATES_API_PATH}/add`;
export const EDIT_ZPL_TEMPLATE_API_PATH = `${ZPL_TEMPLATES_API_PATH}/edit?id={zplTemplateId}`;
export const DELETE_ZPL_TEMPLATE_API_PATH = `${ZPL_TEMPLATES_API_PATH}?id={zplTemplateId}`;

export const DATA_INTEGRATIONS_API_PATH = "data-integration";
export const GET_ALL_DATA_INTEGRATIONS_API_PATH = `${DATA_INTEGRATIONS_API_PATH}/all`;

export const DATA_SETTINGS_API_PATH = "data-setting";
export const GET_ALL_DATA_SETTINGS_API_PATH = `${DATA_SETTINGS_API_PATH}/all`;
export const GET_ALL_DATA_SETTINGS_WITH_UPLOADED_MANIFESTS_MANIFEST_API_PATH = `${DATA_SETTINGS_API_PATH}/all-with-template-files-manifest?manifest_id={manifestId}`;
export const GET_ALL_DATA_SETTINGS_WITH_UPLOADED_MANIFESTS_MANIFEST_GROUP_API_PATH = `${DATA_SETTINGS_API_PATH}/all-with-template-files-manifest-group?manifest_group_id={manifestGroupId}`;
export const GET_SINGLE_DATA_SETTING_API_PATH = `${DATA_SETTINGS_API_PATH}?id={vendorId}`;
export const ADD_DATA_SETTING_API_PATH = `${DATA_SETTINGS_API_PATH}/add`;
export const EDIT_DATA_SETTING_API_PATH = `${DATA_SETTINGS_API_PATH}/edit?id={vendorId}`;
export const DELETE_DATA_SETTING_API_PATH = `${DATA_SETTINGS_API_PATH}?id={vendorId}`;

export const UPLOADED_MANIFESTS_API_PATH = "template-file";
export const GET_ALL_UPLOADED_MANIFESTS_API_PATH = `${UPLOADED_MANIFESTS_API_PATH}/all`;
export const GET_ALL_UPLOADED_MANIFESTS_UNPAGINATED_API_PATH = `${UPLOADED_MANIFESTS_API_PATH}/all-unpaginated`;
export const GET_SINGLE_UPLOADED_MANIFEST_API_PATH = `${UPLOADED_MANIFESTS_API_PATH}?id={uploadedManifestId}`;
export const ADD_UPLOADED_MANIFEST_API_PATH = `${UPLOADED_MANIFESTS_API_PATH}/add`;
export const EDIT_UPLOADED_MANIFEST_API_PATH = `${UPLOADED_MANIFESTS_API_PATH}/edit?id={uploadedManifestId}`;
export const DELETE_UPLOADED_MANIFEST_API_PATH = `${UPLOADED_MANIFESTS_API_PATH}?id={uploadedManifestId}`;

export const UPLOADED_MANIFEST_ITEMS_API_PATH = "template-file-item";
export const GET_ALL_UPLOADED_MANIFEST_ITEMS_API_PATH = `${UPLOADED_MANIFEST_ITEMS_API_PATH}/all`;
export const GET_SINGLE_UPLOADED_MANIFEST_ITEM_API_PATH = `${UPLOADED_MANIFEST_ITEMS_API_PATH}?id={uploadedManifestItemId}`;
export const ADD_UPLOADED_MANIFEST_ITEMS_TO_MANIFEST_API_PATH = `${UPLOADED_MANIFEST_ITEMS_API_PATH}/add-to-manifest?template_file_id={uploadedManifestId}&manifest_id={manifestId}&adding_multiple_mode={addMultipleMode}`;
export const ADD_ALL_UPLOADED_MANIFEST_ITEMS_TO_MANIFEST_API_PATH = `${UPLOADED_MANIFEST_ITEMS_API_PATH}/add-all-to-manifest?template_file_id={uploadedManifestId}&manifest_id={manifestId}&adding_multiple_mode={addMultipleMode}`;
export const EDIT_UPLOADED_MANIFEST_ITEM_API_PATH = `${UPLOADED_MANIFEST_ITEMS_API_PATH}/edit?id={uploadedManifestItemId}`;
export const DELETE_UPLOADED_MANIFEST_ITEM_API_PATH = `${UPLOADED_MANIFEST_ITEMS_API_PATH}?id={uploadedManifestItemId}`;

export const ACCOUNT_API_PATH = "account";
export const GET_DASHBOARD_API_PATH = `${ACCOUNT_API_PATH}/dashboard`;

export const ACCOUNT_SETTINGS_API_PATH = "account-settings";
export const GET_ACCOUNT_SETTINGS_API_PATH = `${ACCOUNT_SETTINGS_API_PATH}`;
export const EDIT_ACCOUNT_SETTINGS_API_PATH = `${ACCOUNT_SETTINGS_API_PATH}/edit`;
export const SET_DEFAULT_NEW_PRODUCT_CONDITION_ACCOUNT_SETTINGS_API_PATH = `${ACCOUNT_SETTINGS_API_PATH}/set-new-product-condition?product_condition_id={productConditionId}`;
export const SET_IS_SHOW_HIDDEN_VENDORS_API_PATH = `${ACCOUNT_SETTINGS_API_PATH}/set-is-show-hidden-vendors?value={value}`;

export const MANIFEST_ITEMS_API_PATH = "manifest-item";
export const GET_ALL_MANIFEST_ITEMS_API_PATH = `${MANIFEST_ITEMS_API_PATH}/all`;
export const GET_ALL_MANIFEST_ITEMS_NO_PAGINATION_API_PATH = `${MANIFEST_ITEMS_API_PATH}/no-pagination`;
export const GET_SINGLE_MANIFEST_ITEM_API_PATH = `${MANIFEST_ITEMS_API_PATH}?id={manifestItemId}`;
export const GET_MANIFEST_ITEM_PHOTOS_API_PATH = `${MANIFEST_ITEMS_API_PATH}/photos?id={manifestItemId}`;
export const GET_MANIFEST_ITEM_BRANDS_API_PATH = `${MANIFEST_ITEMS_API_PATH}/brands`;
export const ADD_MANIFEST_ITEM_API_PATH = `${MANIFEST_ITEMS_API_PATH}/add`;
export const UPDATE_MANIFEST_ITEM_QUANTITY_API_PATH = `${MANIFEST_ITEMS_API_PATH}/update-quantity?id={manifestItemId}&qty={qty}`;
export const UPDATE_MANIFEST_ITEM_NOTE_API_PATH = `${MANIFEST_ITEMS_API_PATH}/update-note?id={manifestItemId}`;
export const UPDATE_MANIFEST_ITEM_VENDOR_API_PATH = `${MANIFEST_ITEMS_API_PATH}/update-vendor?id={manifestItemId}&vendor_id={vendorId}`;
export const UPDATE_MANIFEST_ITEM_CATEGORY_API_PATH = `${MANIFEST_ITEMS_API_PATH}/update-category?id={manifestItemId}&category_id={categoryId}`;
export const UPDATE_MANIFEST_ITEM_DEPARTMENT_API_PATH = `${MANIFEST_ITEMS_API_PATH}/update-department?id={manifestItemId}&department_id={departmentId}`;
export const UPDATE_MANIFEST_ITEM_PRODUCT_CONDITION_API_PATH = `${MANIFEST_ITEMS_API_PATH}/update-product-condition?id={manifestItemId}&product_condition_id={productConditionId}`;
export const ADD_UPC_MANIFEST_ITEM_API_PATH = `${MANIFEST_ITEMS_API_PATH}/add-upc`;
export const ADD_ITEM_NUM_MANIFEST_ITEM_API_PATH = `${MANIFEST_ITEMS_API_PATH}/add-item-num`;
export const ADD_BUNDLE_MANIFEST_ITEM_API_PATH = `${MANIFEST_ITEMS_API_PATH}/add-bundle`;
export const EDIT_MANIFEST_ITEM_API_PATH = `${MANIFEST_ITEMS_API_PATH}/edit?id={manifestItemId}`;
export const GET_SHORTENED_TITLE_MANIFEST_ITEM_API_PATH = `${MANIFEST_ITEMS_API_PATH}/get-shortened-title?id={manifestItemId}`;
export const UPDATE_MANIFEST_ITEM_TITLE_API_PATH = `${MANIFEST_ITEMS_API_PATH}/edit-title?id={manifestItemId}`;
export const GET_WALMART_VARIANTS_MANIFEST_ITEM_API_PATH = `${MANIFEST_ITEMS_API_PATH}/get-walmart-variants?id={manifestItemId}`;
export const REPLACE_WITH_WALMART_VARIANT_MANIFEST_ITEM_API_PATH = `${MANIFEST_ITEMS_API_PATH}/replace-with-walmart-variant?id={manifestItemId}&walmart_variant_id={walmartVariantId}`;
export const UPDATE_MANIFEST_ITEM_DESCRIPTION_API_PATH = `${MANIFEST_ITEMS_API_PATH}/update-description?id={manifestItemId}`;
export const ADD_MULTIPLES_DIFFERENT_MANIFEST_MANIFEST_ITEM_API_PATH = `${MANIFEST_ITEMS_API_PATH}/add-multiples-different-manifest`;
export const ADD_MULTIPLES_SAME_MANIFEST_MANIFEST_ITEM_API_PATH = `${MANIFEST_ITEMS_API_PATH}/add-multiples-same-manifest`;
export const DELETE_MANIFEST_ITEM_API_PATH = `${MANIFEST_ITEMS_API_PATH}?id={manifestItemId}`;

export const PRODUCT_CONDITIONS_API_PATH = "product-condition";
export const GET_ALL_PRODUCT_CONDITIONS_API_PATH = `${PRODUCT_CONDITIONS_API_PATH}/all`;
export const GET_SINGLE_PRODUCT_CONDITION_API_PATH = `${PRODUCT_CONDITIONS_API_PATH}?id={productConditionId}`;
export const ADD_PRODUCT_CONDITION_API_PATH = `${PRODUCT_CONDITIONS_API_PATH}/add`;
export const EDIT_PRODUCT_CONDITION_API_PATH = `${PRODUCT_CONDITIONS_API_PATH}/edit?id={productConditionId}`;
export const DELETE_PRODUCT_CONDITION_API_PATH = `${PRODUCT_CONDITIONS_API_PATH}?id={productConditionId}`;

export const PRODUCT_CONDITION_SETTINGS_API_PATH = "product-condition-setting";
export const GET_ALL_PRODUCT_CONDITION_SETTINGS_API_PATH = `${PRODUCT_CONDITION_SETTINGS_API_PATH}/all`;
export const GET_SINGLE_PRODUCT_CONDITION_SETTING_API_PATH = `${PRODUCT_CONDITION_SETTINGS_API_PATH}?id={productConditionSettingId}`;
export const SET_IS_HIDDEN_PRODUCT_CONDITION_SETTING_API_PATH = `${PRODUCT_CONDITION_SETTINGS_API_PATH}/set-is-hidden?id={productConditionSettingId}&value={value}`;
export const SET_IS_READ_DESCRIPTION_SINGLE_PRODUCT_CONDITION_SETTING_API_PATH = `${PRODUCT_CONDITION_SETTINGS_API_PATH}/set-is-read-description?id={productConditionSettingId}&value={value}`;
export const CREATE_PRODUCT_CONDITION_SETTING_API_PATH = `${PRODUCT_CONDITION_SETTINGS_API_PATH}/create-if-not-existing?product_condition_id={productConditionId}`;
export const EDIT_PRODUCT_CONDITION_SETTING_API_PATH = `${PRODUCT_CONDITION_SETTINGS_API_PATH}/edit-frontend`;
export const DELETE_PRODUCT_CONDITION_SETTING_API_PATH = `${PRODUCT_CONDITION_SETTINGS_API_PATH}?id={productConditionSettingId}`;

export const CATEGORIES_API_PATH = "category";
export const GET_ALL_CATEGORIES_API_PATH = `${CATEGORIES_API_PATH}/all`;
export const GET_SINGLE_CATEGORY_API_PATH = `${CATEGORIES_API_PATH}?id={categoryId}`;
export const ADD_CATEGORY_API_PATH = `${CATEGORIES_API_PATH}/add`;
export const EDIT_CATEGORY_API_PATH = `${CATEGORIES_API_PATH}/edit?id={categoryId}`;
export const DELETE_CATEGORY_API_PATH = `${CATEGORIES_API_PATH}?id={categoryId}`;

export const DEPARTMENTS_API_PATH = "department";
export const GET_ALL_DEPARTMENTS_API_PATH = `${DEPARTMENTS_API_PATH}/all`;
export const GET_SINGLE_DEPARTMENT_API_PATH = `${DEPARTMENTS_API_PATH}?id={departmentId}`;
export const ADD_DEPARTMENT_API_PATH = `${DEPARTMENTS_API_PATH}/add`;
export const EDIT_DEPARTMENT_API_PATH = `${DEPARTMENTS_API_PATH}/edit?id={departmentId}`;
export const DELETE_DEPARTMENT_API_PATH = `${DEPARTMENTS_API_PATH}?id={departmentId}`;

export const DOWNLOADS_API_PATH = "download";
export const MANIFEST_DOWNLOAD_API_PATH = `${DOWNLOADS_API_PATH}/download-manifest`;
export const MANIFEST_GROUP_DOWNLOAD_API_PATH = `${DOWNLOADS_API_PATH}/download-manifest-group`;
export const RECEIVING_DOWNLOAD_API_PATH = `${DOWNLOADS_API_PATH}/download-receiving`;

export const DOWNLOAD_LOGS_API_PATH = "download-log";
export const GET_ALL_DOWNLOAD_LOGS_API_PATH = `${DOWNLOAD_LOGS_API_PATH}/all`;
export const GET_SINGLE_DOWNLOAD_LOG_API_PATH = `${DOWNLOAD_LOGS_API_PATH}?id={downloadLogId}`;
export const DELETE_DOWNLOAD_LOG_API_PATH = `${DOWNLOAD_LOGS_API_PATH}?id={downloadLogId}`;

export const DOWNLOAD_SETTINGS_FRONTEND_API_PATH = "download-settings";
export const GET_DOWNLOAD_SETTINGS_API_PATH = `${DOWNLOAD_SETTINGS_FRONTEND_API_PATH}/get`;
export const UPDATE_DOWNLOAD_SETTINGS_API_PATH = `${DOWNLOAD_SETTINGS_FRONTEND_API_PATH}/edit`;

export const ITEM_FIELD_CONFIGS_API_PATH = "item-field-config";
export const GET_ALL_ITEM_FIELD_CONFIGS_API_PATH = `${ITEM_FIELD_CONFIGS_API_PATH}/all`;
export const UPDATE_ALL_ITEM_FIELD_CONFIGS_API_PATH = `${ITEM_FIELD_CONFIGS_API_PATH}/edit`;
export const SET_ITEM_FIELD_CONFIGS_ACCOUNT_SETTINGS_TO_ALL_USERS_API_PATH = `${ITEM_FIELD_CONFIGS_API_PATH}/set-account-settings-to-all-users`;

export const SCAN_SETTING_API_PATH = "scan-setting";
export const GET_SCAN_SETTINGS_API_PATH = `${SCAN_SETTING_API_PATH}`;
export const TOGGLE_DEFAULT_SETTING_API_PATH = `${SCAN_SETTING_API_PATH}/toggle-source?default_setting={defaultSetting}`;

export const CONDITION_FILTERS_API_PATH = "condition-filter";
export const GET_ALL_CONDITION_FILTERS_API_PATH = `${CONDITION_FILTERS_API_PATH}/all`;
export const GET_ALL_CONDITION_FILTERS_PAGINATED_API_PATH = `${CONDITION_FILTERS_API_PATH}/all-paginated`;
export const GET_SINGLE_CONDITION_FILTER_API_PATH = `${CONDITION_FILTERS_API_PATH}?id={conditionFilterId}`;
export const ADD_CONDITION_FILTER_API_PATH = `${CONDITION_FILTERS_API_PATH}/add`;
export const EDIT_CONDITION_FILTER_API_PATH = `${CONDITION_FILTERS_API_PATH}/edit?id={conditionFilterId}`;
export const DELETE_CONDITION_FILTER_API_PATH = `${CONDITION_FILTERS_API_PATH}?id={conditionFilterId}`;

export const SUPPORT_TICKETS_API_PATH = "support-ticket";
export const GET_ALL_SUPPORT_TICKETS_API_PATH = `${SUPPORT_TICKETS_API_PATH}/all`;
export const GET_SINGLE_SUPPORT_TICKET_API_PATH = `${SUPPORT_TICKETS_API_PATH}?id={supportTicketId}`;
export const ADD_SUPPORT_TICKET_API_PATH = `${SUPPORT_TICKETS_API_PATH}/create-ticket`;
export const GET_UNSEEN_TICKETS_API_PATH = `${SUPPORT_TICKETS_API_PATH}/unseen-tickets`;
export const ADD_SUPPORT_TICKET_COMMENT_API_PATH = `${SUPPORT_TICKETS_API_PATH}/add-comment?ticket_id={supportTicketId}`;

export const DOWNLOAD_FIELD_CONFIG_API_PATH = "download-field-config";
export const GET_ALL_DOWNLOAD_FIELD_CONFIGS_API_PATH = `${DOWNLOAD_FIELD_CONFIG_API_PATH}/all`;
export const REORDER_DOWNLOAD_FIELD_CONFIGS_API_PATH = `${DOWNLOAD_FIELD_CONFIG_API_PATH}/reorder`;
export const EDIT_DOWNLOAD_FIELD_CONFIG_API_PATH = `${DOWNLOAD_FIELD_CONFIG_API_PATH}/edit?id={downloadFieldConfigId}`;

export const PALLET_DOWNLOAD_FIELD_CONFIG_API_PATH = "pallet-download-field-config";
export const GET_ALL_PALLET_DOWNLOAD_FIELD_CONFIGS_API_PATH = `${PALLET_DOWNLOAD_FIELD_CONFIG_API_PATH}/all`;
export const REORDER_PALLET_DOWNLOAD_FIELD_CONFIGS_API_PATH = `${PALLET_DOWNLOAD_FIELD_CONFIG_API_PATH}/reorder`;
export const EDIT_PALLET_DOWNLOAD_FIELD_CONFIG_API_PATH = `${PALLET_DOWNLOAD_FIELD_CONFIG_API_PATH}/edit?id={downloadFieldConfigId}`;

export const OUTGOING_TRUCK_DOWNLOAD_FIELD_CONFIG_API_PATH = "inventory/outgoing-truck-download-field-config";
export const GET_ALL_OUTGOING_TRUCK_DOWNLOAD_FIELD_CONFIGS_API_PATH = `${OUTGOING_TRUCK_DOWNLOAD_FIELD_CONFIG_API_PATH}/all`;
export const REORDER_OUTGOING_TRUCK_DOWNLOAD_FIELD_CONFIGS_API_PATH = `${OUTGOING_TRUCK_DOWNLOAD_FIELD_CONFIG_API_PATH}/reorder`;
export const EDIT_OUTGOING_TRUCK_DOWNLOAD_FIELD_CONFIG_API_PATH = `${OUTGOING_TRUCK_DOWNLOAD_FIELD_CONFIG_API_PATH}/edit?id={downloadFieldConfigId}`;

export const USER_API_PATH = "user";
export const GET_ALL_ACCOUNT_USERS_API_PATH = `${USER_API_PATH}/all`;
export const ADD_USER_API_PATH = `${USER_API_PATH}/add`;
export const EDIT_USER_API_PATH = `${USER_API_PATH}/edit`;

export const ECOMMERCE_SETTINGS_API_PATH = "ecommerce-settings";
export const ECOMMERCE_EDIT_SETTINGS_API_PATH = `${ECOMMERCE_SETTINGS_API_PATH}/edit`;
export const ECOMMERCE_API_PATH = `ecommerce`;
export const GET_ECOMMERCE_LIST_ITEMS_API_PATH = `${ECOMMERCE_API_PATH}/all-list-items`;
export const GET_ECOMMERCE_SHOPIFY_LIST_API_PATH = `${ECOMMERCE_API_PATH}/shopify-list-items`;
export const GET_ECOMMERCE_SQUARE_LIST_API_PATH = `${ECOMMERCE_API_PATH}/square-list-items`;
export const GET_ECOMMERCE_CLOVER_LIST_API_PATH = `${ECOMMERCE_API_PATH}/clover-list-items`;

export const ECOMMERCE_QUEUE_API_PATH = "ecommerce-queue";
export const ECOMMERCE_QUEUE_ALL_PAGINATED_LIST_API_PATH = `${ECOMMERCE_QUEUE_API_PATH}/all`;

export const ECOMMERCE_CLOVER_API_PATH = "clover-settings";
export const GET_ECOMMERCE_CLOVER_SETTINGS_API_PATH = `${ECOMMERCE_CLOVER_API_PATH}/all-unpaginated`;
export const ADD_ECOMMERCE_CLOVER_SETTINGS_API_PATH = `${ECOMMERCE_CLOVER_API_PATH}/add`;
export const UPDATE_ECOMMERCE_CLOVER_SETTINGS_API_PATH = `${ECOMMERCE_CLOVER_API_PATH}/edit?id={cloverSettingsId}`;
export const DELETE_ECOMMERCE_CLOVER_SETTINGS_API_PATH = `${ECOMMERCE_CLOVER_API_PATH}?id={cloverSettingsId}`;
export const GET_SINGLE_ECOMMERCE_CLOVER_SETTINGS_API_PATH = `${ECOMMERCE_CLOVER_API_PATH}?id={cloverSettingsId}`;

export const USER_ROLE_API_PATH = "user-role";
export const GET_ALL_USER_ROLES_API_PATH = `${USER_ROLE_API_PATH}/all`;
export const ADD_USER_ROLE_API_PATH = `${USER_ROLE_API_PATH}/add`;
export const EDIT_USER_ROLE_API_PATH = `${USER_ROLE_API_PATH}/edit?id={userRoleId}`;
export const DELETE_USER_ROLE_API_PATH = `${USER_ROLE_API_PATH}?id={userRoleId}`;
export const GET_SINGLE_USER_ROLE_API_PATH = `${USER_ROLE_API_PATH}?id={userRoleId}`;

export const TABLE_FIELD_API_PATH = "table-field";
export const GET_ALL_TABLE_FIELDS_API_PATH = `${TABLE_FIELD_API_PATH}/all`;

export const USER_ROLE_PERMISSION_API_PATH = "user-role-permission";
export const GET_ALL_USER_ROLE_PERMISSIONS_API_PATH = `${USER_ROLE_PERMISSION_API_PATH}/all?role_id={userRoleId}`;
