import qs from "query-string";

export const setQueryStringWithoutPageReload = (pathname, qsValue, history) => {
  history.push({
    pathname: pathname,
    search: qsValue,
  });
};

export const getQueryStringValue = (key, queryString = window.location.search) => {
  const values = qs.parse(queryString);
  return values[key];
};

export const setQueryStringValue = (key, value, history, queryString = window.location.search) => {
  const values = qs.parse(queryString);
  const newQsValue = qs.stringify({
    ...values,
    [key]: value,
  });
  setQueryStringWithoutPageReload(window.location.pathname, `?${newQsValue}`, history);
};

export const getQueryFilteringObjectFromString = (key, queryString = window.location.search) => {
  const filteringString = qs.parse(queryString)[key];
  const values = {};
  if (filteringString === undefined || filteringString === "") {
    return values;
  }
  filteringString.split("&").forEach((value) => {
    const splits = value.split("=");
    const valueSplits = splits[1].split("|").filter((x) => x);
    values[splits[0]] = valueSplits.length ? valueSplits.map((i) => i) : [];
  });
  return values;
};

export const getQueryFilteringObjectFromStringWithPlainValues = (key, queryString = window.location.search) => {
  /**
   * Returns query parameters as an object with values as plain strings or arrays.
   * - Single values are returned as strings.
   * - Multiple values (separated by "|") are returned as arrays.
   *
   * This function is a variant of getQueryFilteringObjectFromString
   * to avoid breaking changes in existing functionality.
   */
  const filteringString = qs.parse(queryString)[key];
  const values = {};

  if (filteringString === undefined || filteringString === "") {
    return values;
  }

  filteringString.split("&").forEach((value) => {
    const splits = value.split("=");
    const valueSplits = splits[1].split("|").filter((x) => x);

    const convertedValues = valueSplits.map((val) => {
      if (val.toLowerCase() === "true") return true;
      if (val.toLowerCase() === "false") return false;
      const num = Number(val);
      return isNaN(num) ? val : num;
    });

    values[splits[0]] = convertedValues.length === 1 ? convertedValues[0] : convertedValues;
  });

  return values;
};

export const checkIfArrayTypeFilterIsSet = (filteringObject) => {
  return filteringObject?.length !== 0 && filteringObject?.length !== undefined;
};
export const checkIfNumericTypeFilterIsSet = (filteringObject) => {
  return !isNaN(filteringObject);
};

export const checkIfStringTypeFilterIsSet = (filteringObject) => {
  return filteringObject !== undefined && filteringObject !== null && filteringObject !== "";
};
